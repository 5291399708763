import { withTheme } from '@emotion/react'
import React from 'react'

import HeaderStyles from './Header.styles'

const GlobalStyles = withTheme(() => (
  <>
    <HeaderStyles />
  </>
))

export default GlobalStyles
