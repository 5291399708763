import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const CtaBarStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-header {
        .gatsby-image-wrapper {
          margin-bottom: 0 !important;
        }
      }
    `}
  />
))

export default CtaBarStyles
