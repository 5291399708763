module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.fidiumfiberservice.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-5JK3KG5M","brandTokens":["FDM","FDMB"],"mapiBrandToken":"FDM","siteName":"fidiumfiberservice","alternateName":"fidiumfiberservice.com","siteURL":"https://www.fidiumfiberservice.com","defaultTitleTemplate":"","defaultPhone":"8339224481","phoneSymbol":"-","defaultPromoCode":"170894","smartyStreetsWebsiteKey":"","addressQualificationCode":"att-saraplus","vwoProjectID":"894940"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"fidiumfiberservice","short_name":"fidiumfiberservice","start_url":"/","background_color":"#4D72B5","theme_color":"#4D72B5","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"468d41ab773f1eed5c638cf0934868e4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
